// extracted by mini-css-extract-plugin
export var __wab_imgSpacer = "PlasmicFooter-module--__wab_img-spacer--Z-BdC";
export var freeBox__cBoOj = "PlasmicFooter-module--freeBox__cBoOj--NZToA";
export var freeBox__o1Mvk = "PlasmicFooter-module--freeBox__o1Mvk--pb15W";
export var freeBox__tCok0 = "PlasmicFooter-module--freeBox__tCok0--ewqqt";
export var freeBox__wYuw2 = "PlasmicFooter-module--freeBox__wYuw2--QA6nP";
export var freeBox__zFHfQ = "PlasmicFooter-module--freeBox__zFHfQ--ZSSnP";
export var img = "PlasmicFooter-module--img--UMYPc";
export var link__iQsa = "PlasmicFooter-module--link__iQsa---nQkT";
export var link__lS0Wo = "PlasmicFooter-module--link__lS0Wo---Yjgj";
export var link__ncsfB = "PlasmicFooter-module--link__ncsfB--Kf4I+";
export var link__qAzeH = "PlasmicFooter-module--link__qAzeH--WZ51N";
export var link__qhpK3 = "PlasmicFooter-module--link__qhpK3--c1SVv";
export var link__wgCye = "PlasmicFooter-module--link__wgCye--KEJlD";
export var link__xll3B = "PlasmicFooter-module--link__xll3B--TQQ5Q";
export var link__zcMe9 = "PlasmicFooter-module--link__zcMe9--D1s4b";
export var root = "PlasmicFooter-module--root--kbZNx";
export var svg___1CVmU = "PlasmicFooter-module--svg___1CVmU--N3fhY";
export var svg__advro = "PlasmicFooter-module--svg__advro--+67HH";
export var svg__cymJi = "PlasmicFooter-module--svg__cymJi--CtHQd";
export var svg__i7Cah = "PlasmicFooter-module--svg__i7Cah--kVYY-";
export var svg__mzFaz = "PlasmicFooter-module--svg__mzFaz--Q96Zx";
export var svg__plTpQ = "PlasmicFooter-module--svg__plTpQ--8K2oR";
export var svg__pxVmv = "PlasmicFooter-module--svg__pxVmv--Zktpx";
export var svg__r62Rt = "PlasmicFooter-module--svg__r62Rt--eK0ju";
export var svg__tjMv2 = "PlasmicFooter-module--svg__tjMv2--UA3iV";
export var svg__vrZ6 = "PlasmicFooter-module--svg__vrZ6--3t2tB";
export var svg__zFKn = "PlasmicFooter-module--svg__zFKn--mY2Rr";
export var text___5CrdO = "PlasmicFooter-module--text___5CrdO--xBPAd";
export var text__blgr7 = "PlasmicFooter-module--text__blgr7--fSEIo";
export var text__gKuHw = "PlasmicFooter-module--text__gKuHw--2G6fh";
export var text__h2TLw = "PlasmicFooter-module--text__h2TLw--bgVkz";
export var text__pk9N = "PlasmicFooter-module--text__pk9N--gWoeb";
export var text__qzSm7 = "PlasmicFooter-module--text__qzSm7--phGau";
export var text__yrtyl = "PlasmicFooter-module--text__yrtyl--587ew";